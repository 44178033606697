function GoodreadsWidget() {
  return (
    <div className="goodreads">
      <a
        className="gr_title"
        style={{ fontSize: "1.3em", color: "#382110", textDecoration: "none" }}
        href="https://www.goodreads.com/author/show/20606471.Tristan_Gray?utm_medium=api&utm_source=author_widget"
      >
        Goodreads Reviews
      </a>
      <div id="gr_author_widget_1699907161">
        <div className="gr_container">
          <div className="gr_book_container">
            <a
              title="Whispers to a Crow (Tales of the Seann Àite #1)"
              className="gr_book_image"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/55061225-whispers-to-a-crow?utm_medium=api&utm_source=author_widget"
            >
              <img
                alt="Whispers to a Crow"
                src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1598076066l/55061225._SY75_.jpg"
              />
            </a>
            <a
              className="gr_book_title"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/55061225-whispers-to-a-crow?utm_medium=api&utm_source=author_widget"
            >
              Whispers to a Crow (#1)
            </a>
            <br />
            <div className="gr_review_stats">
              reviews: 81
              <br />
              ratings: 126 <br />
              (avg rating 3.94)
            </div>
          </div>

          <div className="gr_book_container">
            <a
              title="Names of the Dead (Tales of the Seann Àite #2)"
              className="gr_book_image"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/55814378-names-of-the-dead?utm_medium=api&utm_source=author_widget"
            >
              <img
                alt="Names of the Dead"
                src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1603917366l/55814378._SY75_.jpg"
              />
            </a>
            <a
              className="gr_book_title"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/55814378-names-of-the-dead?utm_medium=api&utm_source=author_widget"
            >
              Names of the Dead (#2)
            </a>
            <br />
            <div className="gr_review_stats">
              reviews: 27
              <br />
              ratings: 43 <br />
              (avg rating 4.49)
            </div>
          </div>

          <div className="gr_book_container">
            <a
              title="A Gift of the Sea (Tales of the Seann Àite #3)"
              className="gr_book_image"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/58399751-a-gift-of-the-sea?    utm_medium=api&utm_source=author_widget"
            >
              <img
                alt="A Gift of the Sea"
                src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1624380459l/58399751._SX50_.jpg"
              />
            </a>
            <a
              className="gr_book_title"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/58399751-a-gift-of-the-sea?utm_medium=api&utm_source=author_widget"
            >
              A Gift of the Sea (#3)
            </a>
            <br />
            <div className="gr_review_stats">
              reviews: 19
              <br />
              ratings: 23 <br />
              (avg rating 4.65)
            </div>
          </div>

          <div className="gr_book_container">
            <a
              title="The Woven Thread (Tales of the Seann Àite #4)"
              className="gr_book_image"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/60679169-the-woven-thread?  utm_medium=api&utm_source=author_widget"
            >
              <img
                alt="The Woven Thread"
                src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1648828392i/60679169._SX50_.jpg"
              />
            </a>
            <a
              className="gr_book_title"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/60679169-the-woven-thread?utm_medium=api&utm_source=author_widget"
            >
              The Woven Thread (#4)
            </a>
            <br />
            <div className="gr_review_stats">
              reviews: 9
              <br />
              ratings: 13 <br />
              (avg rating 4.69)
            </div>
          </div>

          <div className="gr_book_container">
            <a
              title="Eyes of Gold (Tales of the Seann Àite #5)"
              className="gr_book_image"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/195855750-eyes-of-gold? utm_medium=api&utm_source=author_widget"
            >
              <img
                alt="Eyes of Gold"
                src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1695614990l/195855750._SX50_.jpg"
              />
            </a>
            <a
              className="gr_book_title"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/195855750-eyes-of-gold?utm_medium=api&utm_source=author_widget"
            >
              Eyes of Gold (#5)
            </a>
            <br />
            <div className="gr_review_stats">
              reviews: 7
              <br />
              ratings: 10 <br />
              (avg rating 4.6)
            </div>
          </div>
          <div className="gr_book_container">
            <a
              title="The Red Raven (Tales of the Seann Àite #6)"
              className="gr_book_image"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/212762574-the-red-raven? utm_medium=api&utm_source=author_widget"
            >
              <img
                alt="The Red Raven"
                src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1714831037i/212762574._SY75_.jpg"
              />
            </a>
            <a
              className="gr_book_title"
              rel="nofollow"
              href="https://www.goodreads.com/book/show/212762574-the-red-raven?utm_medium=api&utm_source=author_widget"
            >
              The Red Raven (#6)
            </a>
            <br />
            <div className="gr_review_stats">
              reviews: 1
              <br />
              ratings: 1 <br />
              (avg rating 4)
            </div>
          </div>
        </div>
      </div>
      <div id="goodreads-widget">
        <div id="gr_header">
          <h1>
            <a
              rel="nofollow"
              href="https://www.goodreads.com/book/show/55061225-whispers-to-a-crow"
            >
              Goodreads reviews for Whispers to a Crow
            </a>
          </h1>
        </div>
        <iframe
          title="the_iframe"
          src="https://www.goodreads.com/api/reviews_widget_iframe?did=DEVELOPER_ID&format=html&header_text=Goodreads+reviews+for+Whispers+to+a+Crow&isbn=1838485406&links=660&review_back=fff&stars=000&text=000"
          width="565"
          height="400"
        ></iframe>
        <div id="gr_footer">
          <a
            className="gr_branding"
            target="_blank"
            rel="nofollow noopener noreferrer"
            href="https://www.goodreads.com/book/show/55061225-whispers-to-a-crow?utm_medium=api&utm_source=reviews_widget"
          >
            Reviews from Goodreads.com
          </a>
        </div>
      </div>
    </div>
  );
}

export default GoodreadsWidget;
